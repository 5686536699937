import Vue from 'vue'
import Vuex from 'vuex'
import loginRoot from './loginRoot'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        isShowLoading: false, // 全局 loading
        subjectColor: '#FFFFFF', //主题颜色
        tagColor: '#51CBCD',
        goodsInfo: {},
        // baseUrl:'https://api.jinglu.vip', //上传域名
        uploadingUrl: 'https://crm.jinglu.vip/api/file/uploadObjectOSS', //上传域名
        ossUrl: 'https://ma-oos.oss-cn-beijing.aliyuncs.com/', //静态资源域名
        routerArr: [],
        empId: '' //部门id
    },
    mutations: {
        SET_GOODSINFO(state, data) {
            state.goodsInfo = data;
        },
        setRouterArr(state, data) {
            state.routerArr = data
        },
        setEmpId(state, data) {
            state.empId = data
        }
    },
    modules: {
        loginRoot
    }
})

export default store